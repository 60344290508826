import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import convertToHTML from "markdown-to-html-converter"
import { Link } from "gatsby"
import slugify from "slugify"
import { navigate } from "@reach/router"
import Img from "gatsby-image"
import SEO from "src/components/global/SEO.js"
import noHangers from "src/components/global/fn/noHangers.js"
import Navigation from "src/components/global/Navigation.js"
import Footer from "src/components/global/Footer"
import typographySizes from "src/assets/styles/typographySizes.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import colors from "src/assets/styles/colors.js"
import Cookies from "src/components/global/Cookies.js"

import BigText from "src/components/global/typography/BigText"
import MidText from "src/components/global/typography/MidText"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import ReactMarkdown from "react-markdown"

import InformationSection from "src/components/Project/InformationSection.js"
import Wrapper from "src/components/global/Wrapper"
import NewBusinessSection from "src/components/global/NewBusinessSection.js"
import Slider from "src/components/global/Slider"
import SliderChangeImg from "src/components/global/SliderChangeImgGit"

import NumbersSection from "src/components/Project/NumbersSection.js"
import ProjectCard from "src/components/whatWeDo/ProjectCard.js"
import Projects from "src/components/global/Projects.js"
import Divider from "src/components/global/Divider.js"

const { htmlToText } = require("html-to-text")

const goBack = () => {
  navigate(-1)
}
const BackLink = styled(Link)`
  font-size: ${typographySizes.s}px;
  text-decoration: none;
  cursor: pointer;

  margin-top: 20px;
  @media (max-width: ${mediaQuery.laptop}) {
    order: -1;
    margin-top: 0px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    order: -1;
    margin-top: 20px;
  }
`

const Header = styled(Wrapper)`
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;
`

const HeaderImg = styled(Img)`
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: 100%;
  }
`
const TitleCompetanceWrapper = styled.div`
  width: 58.33%;
  margin-left: 8.33%;
  align-self: flex-end;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-left: 0%;
    display: flex;
    flex-direction: column-reverse;
  }
`

const HeaderTitle = styled(BigText)`
  margin-top: 20px;

  @media (max-width: ${mediaQuery.laptop}) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

const CompetanceWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  @media (max-width: ${mediaQuery.laptop}) {
    margin-top: 20px;
    margin-bottom: 0px;
  }
`

const Competance = styled.p`
  font-size: ${typographySizes.s}px;
  margin-right: 20px;
`

const HeaderCredits = styled.p`
  font-size: ${typographySizes.s}px;
  color: ${colors.darkGray};
  padding: 5px;
  background-color: ${colors.white};
  position: absolute;
  left: 5px;
  bottom: 0;
  opacity: 0;

  transform: translate(0, 100%);
  transition: all 0.5s;
  @media (max-width: ${mediaQuery.tablet}) {
    position: relative;
    opacity: 1;
    padding: 0px;
    left: 0px;
  }
`

const HeaderImgWrapper = styled.div`
  width: 33.33%;
  height: 23vw;
  overflow: hidden;
  position: relative;
  transition: all 0.5s;
  /* align-self: flex-end; */
  @media (max-width: ${mediaQuery.tablet}) {
    overflow: visible;
    width: 100%;
    height: 69vw;
  }
  :hover ${HeaderCredits} {
    transform: translate(0, calc(0% - 5px));
    transition: all 0.5s;
    opacity: 1;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    margin-bottom: 50px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 50px;
  }
`

const HeaderParagraph = styled(MidText)`
  width: 58.33%;
  margin-top: 50px;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`
const ProjectWrapper = styled(Wrapper)`
  position: relative;
`

const ProjectLayout = ({ data, pageContext }) => {
  const htmlStrTitle = convertToHTML(data.strapiProjects.First_Paragraph)
  const htmlTextTittle = htmlToText(htmlStrTitle, {
    wordwrap: 130,
  })
  let seoTitle = htmlTextTittle.replace(/ *\[[^)]*\] */g, " ")

  const htmlStrDescription = convertToHTML(data.strapiProjects.Second_Paragraph)
  const htmlTextDescription = htmlToText(htmlStrDescription, {
    wordwrap: 160,
  })
  let seoDescription = htmlTextDescription.replace(/ *\[[^)]*\] */g, " ")

  return (
    <>
      <Cookies />
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={data.strapiProjects.Thumbnail.localFile.publicURL}
      />

      <Navigation
        lang={pageContext.locale}
        langUrl={pageContext.langUrl}
        titleLang={
          data.titleLang &&
          slugify(data.titleLang?.Title, { lower: true, strict: true })
        }
        navBtnLink={data.strapiGlobal.Nav_btn_link}
        navBtnText={data.strapiGlobal.Nav_btn_text}
      />
      {console.log(data)}
      <Header>
        <HeaderImgWrapper>
          <HeaderImg
            fluid={
              data.strapiProjects.Thumbnail.localFile.childImageSharp.fluid
            }
          />
          {data.strapiProjects.Thumbnail_Credits ? (
            <HeaderCredits>
              {data.strapiProjects.Thumbnail_Credits}
            </HeaderCredits>
          ) : null}
        </HeaderImgWrapper>
        <TitleCompetanceWrapper>
          <CompetanceWrapper>
            {data.strapiProjects.competences.map(document => (
              <Competance>{document.Name}</Competance>
            ))}
          </CompetanceWrapper>
          <Divider />
          <HeaderTitle>
            <ReactMarkdown
              className={"marginP"}
              renderers={{
                link: props => (
                  <a href={props.href} rel="noreferrer" target="_blank">
                    {props.children}
                  </a>
                ),
              }}
              source={noHangers(data.strapiProjects.First_Paragraph)}
            />
          </HeaderTitle>
        </TitleCompetanceWrapper>
        {/* <BackLink to={"/corobimy#Project"}>← powrot do projektow</BackLink> */}
        <BackLink
          to={`/${pageContext.locale === "en" ? "en/clients" : "klienci"}`}
        >
          {pageContext.locale === "en"
            ? "← back to clients"
            : "← powrot do klientów"}
        </BackLink>
        <HeaderParagraph>
          <ReactMarkdown
            className={"marginP"}
            renderers={{
              link: props => (
                <a href={props.href} rel="noreferrer" target="_blank">
                  {props.children}
                </a>
              ),
            }}
            source={noHangers(data.strapiProjects.Second_Paragraph)}
          />
        </HeaderParagraph>
      </Header>

      {data.strapiProjects.Content.map(document => {
        if (
          document.Information_Title !== null &&
          document.Information_Title !== undefined
        ) {
          return (
            <InformationSection
              key={document.id}
              Title={document.Information_Title}
              Highlighted={noHangers(
                document.Information_Highlighted_Paragraph
              )}
              Paragraph={noHangers(document.Information_Paragraph)}
            />
          )
        }
        if (document.Slider !== null && document.Slider !== undefined) {
          return (
            <SliderChangeImg
              key={document.id}
              allFile={pageContext.allFiles.data.allFile}
              Gallery={document.Slider}
              sliderWidth={75}
              credits={document.Slider_Credits}
            />
          )
        }
        if (document.Number_Box !== null && document.Number_Box !== undefined) {
          return <NumbersSection Content={document.Number_Box} />
        }
      })}
      <Projects
        // marginTop={0}
        title={
          pageContext.locale === "en"
            ? "check out other projects"
            : "zobacz inne projekty"
        }
        projects={data.allStrapiProjects.edges}
        locale={pageContext.locale}
      />
      {/* <ProjectWrapper>
        <SmallTitle>
          {pageContext.locale === "en"
            ? "check out other projects"
            : "zobacz inne projekty"}
        </SmallTitle>

        {data.allStrapiProjects.edges.map(document => (
          <ProjectCard
            active={"all"}
            img={document.node.Thumbnail.localFile.childImageSharp.fluid}
            title={document.node.Title}
            competences={document.node.competences}
          />
        ))}
      </ProjectWrapper> */}
      <NewBusinessSection
        claim={data.strapiContactPageN.New_business_Claim}
        title={data.strapiContactPageN.New_business_Title}
        aboutBtn={data.strapiContactPageN.About_Btn}
        briefBtn={data.strapiContactPageN.Brief_btn}
        onlyPl={true}
      />
      <Footer lang={pageContext.locale} companyData={data.strapiContactPageN} />
    </>
  )
}
export const query = graphql`
  query ProjectLayout($id: String!, $locale: String!, $idLang: Int!) {
    strapiGlobal(locale: { eq: $locale }) {
      Nav_btn_link
      Nav_btn_text
    }
    strapiContactPageN(locale: { eq: $locale }) {
      New_business_Claim
      New_business_Title
      About_Btn {
        Hover
        Main
        Mobile
      }
      Brief_btn {
        Hover
        Main
        Mobile
        Link
      }
    }
    titleLang: strapiProjects(strapiId: { eq: $idLang }) {
      Title
    }
    strapiProjects(id: { eq: $id }) {
      id
      Title
      First_Paragraph
      Second_Paragraph
      Thumbnail_Credits
      competences {
        Name
      }
      Thumbnail {
        localFile {
          publicURL
          childImageSharp {
            fluid(
              maxWidth: 700
              quality: 80
              traceSVG: { background: "#ffffff", color: "#ffff00" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      Content
    }
    allStrapiProjects(
      sort: { fields: created_at, order: DESC }
      limit: 4
      filter: { id: { ne: $id }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          Title
          created_at

          Thumbnail {
            localFile {
              publicURL
              childImageSharp {
                fluid(
                  maxWidth: 700
                  quality: 80
                  traceSVG: { background: "#ffffff", color: "#ffff00" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          competences {
            Name
          }
        }
      }
    }
    strapiContactPageN(locale: { eq: $locale }) {
      Company_details_Address
      Company_details_Krs
      Company_details_Name
      Company_details_Nip
      Company_details_Regon
      Company_details_Title
    }
  }
`

export default ProjectLayout
